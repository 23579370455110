import React, { useState } from "react"
import PropTypes from "prop-types"

import BrandButton from "@components/common/BrandButton"
import language from "@language"
import { withLocation } from "@utilities/componentUtils"
import CopyLink from "@images/icons/link.svg"
import classnames from "classnames"

import "./style.scss"

const CopyLinkButton = props => {
  const [buttonText, setButtonText] = useState(
    language[props.language].copy_the_link
  )

  // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  const handleCopyLink = () => {
    copyTextToClipboard(props.browserLocation.href)
  }

  const fallbackCopyTextToClipboard = text => {
    var textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand("copy")
      // var successful = document.execCommand("copy")
      // var msg = successful ? "successful" : "unsuccessful"
      // console.log("Fallback: Copying text command was " + msg)
      setButtonText(`${language[props.language].link_copied}`)
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err)
    }

    document.body.removeChild(textArea)
  }
  const copyTextToClipboard = text => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      () => {
        setButtonText(`${language[props.language].link_copied}`)
        // console.log("Async: Copying to clipboard was successful!")
      },
      err => {
        console.error("Async: Could not copy text: ", err)
      }
    )
  }

  return (
    <BrandButton
      className={classnames("copy-link-button", props.className)}
      btnStyle="outline"
      color="primary"
      onClick={handleCopyLink}
      icon={<img className="responsive-image" alt="copia" src={CopyLink} />}
      text={buttonText}
    />
  )
}

CopyLinkButton.propTypes = {
  browserLocation: PropTypes.object,
  language: PropTypes.string,
}
CopyLinkButton.defaultProps = {
  language: "es",
}

export default withLocation(CopyLinkButton)
